ion-card {
    margin-left: 13%;
    margin-right: 13%;
    align-content: flex-start;
    width: 80%;
    padding: 10px 2% 25px;
}

ion-button {
    margin-left: 6px;
    width: 8%;
    font-size: 12pt;
}