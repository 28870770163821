.row-text {
    font-size: 16px;
    font-family: opensans;
    color: "#444444";
}

ion-card {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    align-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

ion-card-header {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: "#333";
    padding-bottom: 30px;
    text-align: center;
}

.order-count-btn {
    width: 100%;
}
