ion-row {
    font-size: 42px;
    font-family: Gill Sans;
    color: "#1d4c84";
    text-align: center;
    justify-content: center;
    padding: 20px;
}

#begin-button {
    width: 40%;
    border-radius: 4px;
}

ion-title {
    text-align: center;
    font-family: Gill Sans;
    color: #1d4c84
}

#row-with-note {
    text-align: left
}

ion-button {
    --background: #7f32a8
}

ion-card-title {
    text-align: center;
    font-family: Gill Sans;
    color: #1d4c84
}

#warning-text {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Open Sans', 'sans-serif';
    color: '#444444';
    padding-top: 50px;
}

.card-title {
    text-align: center;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    color: #333;
    padding-bottom: 30px;
}

ion-card-content {
    padding: 20px;
}

ion-item {
    --border-color: #d1d3d4;
    --highlight-color-invalid: red;
    --highlight-color-valid: green;
    flex: content;
}
  
ion-card {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    padding: 20px;
    width: 80%;
    align-content: center;
}

ion-col {
    text-align: center;
}

ion-label {
    /* font-weight: bold; */
    margin: 0;
    display: block;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
.order-form-btn {
    width: 100%
}

#thank-you-item {
    text-align: center;
    justify-content: center;
}

/* 
.legal-doc-txt {
    text-align: center;
    justify-content: center;
} */

.legal-doc-text {
    padding-left: 150px;
    padding-right: 150px; 
    justify-content: center;

}

